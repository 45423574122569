


import React from 'react';

const WhatsAppIcon = () => {
  return (
    <div className="fixed bottom-2  z-50 sm:bottom-6 sm:left-2 md:bottom-8 md:left-4 flex flex-col ">
      {/* LinkedIn Icon */}
      <a
        href="https://www.linkedin.com/company/ratsmschennai/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:scale-110 transition-transform duration-300 p-1 sm:p-2 "
        aria-label="Read more about Seminole tax hike"
      >
        <i className="fab fa-linkedin text-xl sm:text-3xl md:text-3xl lg:text-4xl text-blue-600 hover:text-blue-800" />
      </a>

      {/* YouTube Icon */}
      <a
        href="https://www.youtube.com/@ratsms"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:scale-110 transition-transform duration-300 p-1 "
        aria-label="Read more about Seminole tax hike"
      >
        <i className="fab fa-youtube text-xl sm:text-3xl md:text-3xl lg:text-4xl text-red-600 hover:text-red-800" />
      </a>

      {/* Instagram Icon */}
      <a
        href="https://www.instagram.com/ratsms_com/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:scale-110 transition-transform duration-300 p-1 sm:p-2 "
        aria-label="Read more about Seminole tax hike"
      >
        <i className="fab fa-instagram text-xl sm:text-3xl md:text-3xl lg:text-4xl  text-pink-500 hover:text-pink-700" />
      </a>

      {/* Facebook Icon */}
      <a

        href="https://www.facebook.com/ratsmschennai"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:scale-110 transition-transform duration-300 p-1 sm:p-2 "
        aria-label="Read more about Seminole tax hike"
      >
        <i className="fab fa-facebook text-xl sm:text-3xl md:text-3xl lg:text-4xl text-[#1877F2] hover:text-blue-900" />
      </a>

      {/* WhatsApp Icon */}
   

    </div>
  );
};

export default WhatsAppIcon;

